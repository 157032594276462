/* eslint-disable no-shadow */
import { authService, userService } from '@/service';
import * as types from './types';
import ProfilePhotoPlaceholder from '@/assets/images/profile-photo-placeholder.svg';
import { buildProfilePhotoURL } from '@/utils/photoUrlBuilder';

/**
 * auth module: token and userInfo manipulation (Public + LoggedIn)
 * @type {{token: *, userInfo: null}}
 */

const state = {
  token: authService.getAccessToken(),
  userInfo: null,
};

const getters = {
  token: state => state.token,
  isAuthenticated: state => !!state.token,
  userInfo: state => state.userInfo,
  profilePhoto: (state) => {
    if (state.userInfo.photo) {
      return buildProfilePhotoURL(state.userInfo.photo);
    }
    return ProfilePhotoPlaceholder;
  },
  hasProfilePhoto: state => !!state.userInfo.photo,
  isCustomer: state => state.userInfo && state.userInfo.roles.includes('ROLE_CUSTOMER'),
  isOwner: state => state.userInfo && state.userInfo.roles.includes('ROLE_OWNER'),
  // isUserInRole: state => role => state.auth && state.auth.details.roles.includes(role),
};

const mutations = {
  [types.SET_AUTH](state, { token, userInfo }) {
    authService.storeAccessToken(token);
    state.token = token;
    state.userInfo = userInfo;
  },
  [types.CLEAR_AUTH](state) {
    authService.removeAccessToken();
    state.token = null;
    state.userInfo = null;
  },
  /* USER INFO */
  [types.SET_USER_INFO](state, userInfo) {
    state.userInfo = userInfo;
  },
  [types.MUTATE_USER_PHOTO](state, photoName) {
    state.userInfo.photo = photoName;
  },
  [types.UPDATE_COMPANY_INFO](state, company) {
    if (state.userInfo) {
      state.userInfo.company = company;
    }
  },
};


const actions = {
  [types.AUTHENTICATE](ctx, { email, password }) {
    return userService.authenticate(email, password)
      .then((response) => {
        ctx.commit(types.SET_AUTH, response.data);
      });
  },
  [types.DEAUTHENTICATE](ctx) {
    return authService.logout()
      .then(() => { ctx.commit(types.CLEAR_AUTH); })
      .catch((e) => { /* do nothing */ });
  },
  [types.REFRESH_TOKEN](ctx) {
    return authService.refreshAccessToken()
      .then((response) => {
        ctx.commit(types.SET_AUTH, response.data);
        return response.data.token;
      });
  },
  [types.UPDATE_USER_INFO]({ commit }) {
    return userService.getInfo()
      .then((response) => {
        commit(types.SET_USER_INFO, response.data);
      }).catch(() => {
        commit(types.CLEAR_AUTH);
      });
  },
  [types.EXTRACT_TOKEN_PAYLOAD]({ state }) {
    const { token } = state;

    if (!token) return null;

    const payload = token.split('.')[1];

    if (payload) {
      // base64 decode
      return JSON.parse(window.atob(payload));
    }
    return null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
