/* eslint-disable no-shadow */
import { LocalDateTime } from '@js-joda/core';
import * as types from './types';


/** Service module for service and cart. (public) * */

/**
 *
 * A service that is currently displayed to the user.
 *
 *  Contains:
 *    - service id,
 *    - service name,
 *    - service description,
 *    - booking conditions (min and max num of people allowed, number of hours to book in advance,
 *       cancellation policy and estimated maximum response time in hours),
 *    - product list as array
 *    - ownerStatement
 *    - remark
 *    - service tags
 *    - service amenities
 *    - category
 *    - primary photo
 *    - service location (country, region, meeting point with latitude and longitude,
 *      about micro-location, near by locations as array)
 *
 *     Each Item has:
 *
 *     - name,
 *     - description,
 *     - id,
 *     - initPrice (initial Price without discount)
 *     - price (calculated with discount),
 *     - type (individual or group),
 *     - groupNumber (max allowed people in group - 0 if type is individual)
 *     - discount rate (0 if there is no discount)
 *     - duration - in seconds (optional)
 *     - difficulty - eg. EASY, HARD... (optional)
 *     - lastModified
 *
 *    Classes as blueprint just for displaying properties
 * */


class DisplayedService {
  // service data input from request
  constructor(data) {
    this.id = data.serviceId;
    this.name = data.title;
    this.conditions = {
      minPeople: Number(data.conditions.minPeople),
      maxPeople: Number(data.conditions.maxPeople),
      inAdvance: Number(data.conditions.advance),
      cancellationPolicy: data.conditions.cancellation.replace(/[^0-9]/g, ''),
      maxResponseTime: data.conditions.maxResponseTime,
    };
    this.category = data.categoryName;
    this.categorySlug = data.categorySlug;
    this.ownerStatement = data.ownerStatement;
    this.tags = data.tags;
    this.description = data.description;
    this.includedInTheService = data.includedInTheService;
    this.amenities = data.amenities;
    this.spLanguages = data.spLanguages;
    this.remark = data.remark;
    this.primaryPhoto = data.primaryPhoto;
    this.lastModified = data.lastModified;
    this.location = {
      region: data.regionName,
      regionSlug: data.regionSlug,
      meetingPoint: data.location,
      country: data.country,
      nearBy: data.nearByLocations,
    };
    this.items = [];
  }

  addItem(item) {
    this.items.push(item);
  }
}

class Item {
  constructor(data) {
    this.name = data.name;
    this.description = data.description;
    this.id = data.identifier;
    this.price = data.calculatedPrice;
    this.initPrice = data.initPrice;
    this.type = data.priceType.value;
    this.groupNumber = data.groupNumber;
    this.discountRate = data.discountRate;
    this.duration = data.duration;
    this.difficulty = data.difficulty;
  }

  isGroupPrice() {
    return this.type.toLowerCase() === 'group';
  }

  isOnDiscount() {
    return (Number(this.discountRate) > 0);
  }
}


/** * State related * */


const state = {
  displayedService: null,
  photoGallery: [],
  brand: {},
  booking: {
    scheduled: {
      date: null,
      time: null,
    },
    order: new Map(),
    session: null,
  },
};
const getters = {
  // displayed service
  displayedService: state => state.displayedService,
  // booking related
  // fetch serviceInfo from session or displayedService
  booking: state => state.booking,
  booking_serviceInfo: (state) => {
    if (state.booking.session) {
      return state.booking.session.serviceInfo;
    }
    const serviceInfo = {};
    serviceInfo.serviceId = state.displayedService.id;
    serviceInfo.title = state.displayedService.name;
    serviceInfo.conditions = state.displayedService.conditions;
    serviceInfo.availableOffers = state.displayedService.items.map((item) => {
      const offer = {};
      offer.offerId = item.id;
      offer.name = item.name;
      offer.price = item.price;
      offer.maxPersons = item.groupNumber;
      return offer;
    });
    return serviceInfo;
  },
  numberOfPeople: (state) => {
    let sum = 0;

    state.booking.order.forEach((quantity, offer) => {
      // sum += offer.isGroup ? (quantity * offer.maxPersons) : quantity;
      if (offer !== undefined) sum += offer.maxPersons > 1 ? (quantity * offer.maxPersons) : quantity;
    });
    return sum;
  },
  hasEnoughPeople: (state, getters) => getters.numberOfPeople >= getters.booking_serviceInfo.conditions.minPeople
    && getters.numberOfPeople <= getters.booking_serviceInfo.conditions.maxPeople,
  priceSum: (state) => {
    let sum = 0;
    state.booking.order.forEach((quantity, offer) => {
      if (offer !== undefined) sum += Number(offer.price) * quantity;
    });
    return sum;
  },
  platformCost: (state, getters) => {
    const SERVICE_FEE_PERCENTAGE = 0;

    return (getters.priceSum * (SERVICE_FEE_PERCENTAGE / 100)).toFixed(2);
  },
  totalCost: (state, getters) => (+getters.priceSum + +getters.platformCost).toFixed(2),
};
const mutations = {
  removeServiceRelatedData(state) {
    state.brand = {};
    state.displayedService = null;
    state.photoGallery = [];
  },
  [types.SET_DISPLAYED_SERVICE](state, displayedService) {
    state.displayedService = displayedService;
  },
  [types.SET_SERVICE_PHOTO_GALLERY](state, gallery) {
    state.photoGallery = gallery;
  },
  [types.SET_BRAND_DETAILS](state, brand) {
    state.brand = brand;
  },

  setScheduledDate(state, date) {
    state.booking.scheduled.date = date;
  },
  setScheduledTime(state, time) {
    state.booking.scheduled.time = time;
  },
  setOrder(state, orderMap) {
    state.booking.order = new Map(orderMap);
  },
  setSession(state, session) {
    state.booking.session = session;
  },
  clearAll(state) {
    state.booking.scheduled.date = null;
    state.booking.scheduled.time = null;
    state.booking.order = new Map();
    state.booking.session = null;
  },
  addOffer(state, data) {
    if (data.quantity === 0) {
      state.booking.order.delete(data.offer);
    } else {
      state.booking.order.set(data.offer, data.quantity);
    }
    // fix for Vue v2.x: trigger reactivity for Map with reassign
    state.booking.order = new Map(state.booking.order);
  },

};
const actions = {
  [types.DISPLAY_SERVICE](context, payload) {
    const displayedService = new DisplayedService(payload);
    // add products/items
    payload.offers.forEach((o) => {
      displayedService.addItem(new Item(o));
    });
    // set displayed service
    context.commit(types.SET_DISPLAYED_SERVICE, displayedService);
  },
  saveSession({ state, commit, getters }, session) {
    commit('setSession', session);
    const scheduled = LocalDateTime.parse(session.scheduled);
    commit('setScheduledDate', scheduled.toLocalDate().toString());
    commit('setScheduledTime', scheduled.toLocalTime().toString());
    // map to order
    const orderMap = new Map();
    session.items.forEach((item) => {
      const offer = getters.booking_serviceInfo.availableOffers.find(o => o.offerId === item.id);
      orderMap.set(offer, item.quantity);
    });
    commit('setOrder', orderMap);
  },
};


export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
