export default class Helpers {
  // 1. determine whether browsers support drag & drop
  // refact later?
  determineDragAndDropCapable(refElement) {
    /* Checking is drag and drop functionality in the window
   * Is 'draggable' or 'ondragstart' and 'ondrop' events in element
   * Does window have 'FormData' and 'FileReader' objects */
    const div = document.createElement('img');
    const isDraggable = (('draggable' in div)
      || ('ondragstart' in div && 'ondrop' in div))
      && 'FormData' in window
      && 'FileReader' in window;
    /* If browser is drag and drop capable for each event we prevent
      default action and stop the propagation */
    if (isDraggable) {
      ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach((e) => {
        refElement.addEventListener(e, (ev) => {
          ev.preventDefault();
          ev.stopPropagation();
        }, false);
      });
    }
    return isDraggable;
  }

  calculateDiscount(regularPrice, discountRate) {
    const x = (regularPrice - (regularPrice * (discountRate / 100))).toFixed(2);
    return x;
  }

  decodeHtml(html) {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  }
}
