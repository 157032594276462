/* eslint-disable no-shadow */
import * as types from './types';

/**
 * seasonAndPricing module: stores season and price details - refactor ? (Provider)
 * @type {{season: {}, offerDetails: {}}}
 */
const state = {
  season: {},
  offerDetails: {},
};

const getters = {
  season: state => state.season,
  offerDetails: state => state.offerDetails,
};

const mutations = {
  [types.SET_SEASON](state, season) {
    state.season = season;
  },
  [types.SET_OFFER](state, priceDetails) {
    state.offerDetails = priceDetails;
  },
};

const actions = {
  [types.SEASON_FOR_EDIT]({ commit }, payload) {
    commit(types.SET_SEASON, payload);
  },
  [types.OFFER_FOR_EDIT]({ commit }, payload) {
    commit(types.SET_OFFER, payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
