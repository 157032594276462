import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { Validator } from 'vee-validate';
// load only en locale
import enLocale from '@/locale/index-en';


Vue.use(VueI18n);

// use from localStorage or fallback to english
const currentLocale = window.localStorage.getItem('lang') || 'en';

// configuration
const i18n = new VueI18n({
  locale: currentLocale,
  fallbackLocale: 'en',
  // silentFallbackWarn: true,
  messages: {
    en: enLocale.translations,
  },
});

// default loaded locales
const loadedLocales = ['en'];

function setLocale(lang) {
  // save to local storage - can be fetched via store
  window.localStorage.setItem('lang', lang);
  // create lang cookie
  document.cookie = `lang=${lang}; path=/;`;
  // set lang attribute on html tag inside document
  document.querySelector('html').setAttribute('lang', lang);
  // change locale globally
  i18n.locale = lang;
  return lang;
}

export function loadLocaleAsync(lang) {
  // Switch to new locale If it was already loaded
  if (loadedLocales.includes(lang)) {
    return Promise.resolve(setLocale(lang));
  }

  // load locale
  return import(/* webpackChunkName: "locale-[request]" */ '@/locale/index-' + lang).then(
    (loadedLocale) => {
      // set translations (messages)
      i18n.setLocaleMessage(lang, loadedLocale.default.translations);
      // merge new dictionary for validator
      Validator.dictionary.merge(loadedLocale.default.validations);

      // add to loaded locales
      loadedLocales.push(lang);

      // save locale
      return setLocale(lang);
    },
  );
}

// load "currentLocale" if not already loaded
if (!loadedLocales.includes(currentLocale)) {
  loadLocaleAsync(currentLocale).then(() => {});
}

export default i18n;

// Each component contains a VueI18n instance referenced as the $i18n property
// that can be used to change the locale.
