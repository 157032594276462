/* eslint-disable no-shadow */
import * as types from './types';
import { loadLocaleAsync } from '@/plugins/i18n';

/**
 * Global store: validation messages, categories, locale, notifications, loader
 * @type {{locale: string | null, categories: Array, messages: {validation: Array,
 * notification: {isSuccess: boolean, content: string}}, loaderDisplay: boolean}}
 */

const state = {
  locale: window.localStorage.getItem('lang'),
  seo: {
    title: '',
  },
  categories: [],
  regions: [],
  messages: {
    validation: [],
    notification: {
      isSuccess: true,
      content: '',
    },
    // error: '',
    // warning: '',
    flash: '',
  },
  loaderDisplay: false,
};

const getters = {
  [types.GET_FLASH_MESSAGE](state) {
    return state.messages.flash;
  },
  categoryBySlug(state) {
    return slug => state.categories.find(c => c.slug === slug);
  },
  regionBySlug(state) {
    return slug => state.regions.find(r => r.slug === slug);
  },
};

const mutations = {
  [types.MUTATE_MESSAGES](state, payload) {
    state.messages[payload.type] = payload.data;
  },
  [types.LOADER_DISPLAY](state, payload) {
    state.loaderDisplay = payload;
  },
  [types.SET_CATEGORIES](state, payload) {
    state.categories = payload;
  },
  [types.SET_REGIONS](state, payload) {
    state.regions = payload;
  },
  'seo:title': (state, title) => {
    state.seo.title = `${title} – Authland`;
    document.title = state.seo.title;
  },
};

const actions = {
  [types.LOAD_LOCALE](context, locale) {
    // load and save
    loadLocaleAsync(locale).then(() => {
      // nothing to do
    });
  },
  [types.NOTIFY]({ commit }, data) {
    const notification = {
      type: 'notification',
      data,
    };
    commit(types.MUTATE_MESSAGES, notification);
  },
  [types.LOADER]({ commit }, data) {
    commit(types.LOADER_DISPLAY, data);
  },
};


export default {
  state,
  getters,
  mutations,
  actions,
};
