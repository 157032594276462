// actions
export const LOAD_LOCALE = 'LOAD_LOCALE';
export const SET_MESSAGES = 'SET_MESSAGES';
export const NOTIFY = 'SEND_NOTIFICATION';
export const LOADER = 'LOADER';
// mutations
export const MUTATE_MESSAGES = 'MUTATE_MESSAGES';
export const LOADER_DISPLAY = 'LOADER_DISPLAY';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_REGIONS = 'SET_REGIONS';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';

// getters
export const GET_FLASH_MESSAGE = 'GET_FLASH_MESSAGE';
