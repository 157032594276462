import Vue from 'vue';
import Vuex from 'vuex';
import global from '@/store/global';
import service from '@/store/modules/service';
import auth from '@/store/modules/auth';
import reservations from '@/store/modules/reservations';
import seasonsAndOffers from '@/store/modules/seasonsAndOffers';
import currencies from '@/store/modules/currencies';

// register Vuex plugin
Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const {
  state, getters, mutations, actions,
} = global;

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    auth,
    service,
    reservations,
    seasonsAndOffers,
    currencies,
  },
  strict: debug,
  // plugins: [],
});
