import Vue from 'vue';
import Router from 'vue-router';
import VueHead from 'vue-head';
import scrollBehavior from '@/routes/config/scrollBehavior';
import authRoutes from '@/routes/public/auth.routes';
import publicRoutes from '@/routes/public/public.routes';
import commonRoutes from '@/routes/common.routes';
import customerRoutes from '@/routes/customer.routes';
import providerRoutes from '@/routes/provider/provider.routes';
import providerDashboardRoutes from '@/routes/provider/dashboard.routes';

const HISTORY_MODE = 'history';

Vue.use(VueHead);
Vue.use(Router);

// const locale = window.location.pathname.replace(/^\/([^\/]+).*/i,'$1');

export default new Router({
  mode: HISTORY_MODE,
  // base: (locale.trim().length && locale !== '/') ? `/${locale}` : window.localStorage.getItem('lang'),
  routes: [
    ...publicRoutes,
    ...authRoutes,
    ...providerRoutes,
    ...providerDashboardRoutes,
    ...customerRoutes,
    ...commonRoutes,
  ],
  scrollBehavior,
});
