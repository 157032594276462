// actions
export const AUTHENTICATE = 'AUTHENTICATE';
export const DEAUTHENTICATE = 'DEAUTHENTICATE';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const EXTRACT_TOKEN_PAYLOAD = 'EXTRACT_TOKEN_PAYLOAD';
// mutations
export const SET_AUTH = 'SET_AUTH';
export const CLEAR_AUTH = 'CLEAR_AUTH';
export const SET_USER_INFO = 'SET_USER_INFO';
export const MUTATE_USER_PHOTO = 'MUTATE_USER_PHOTO';
export const UPDATE_COMPANY_INFO = 'UPDATE_COMPANY_INFO';
