// eslint-disable-next-line import/prefer-default-export
export const ibanValidator = (value, args) => {
  const MOD = 97;

  if (typeof value === 'undefined' || value.length < 5 || value.length > 34) {
    return false;
  }
  const iban = value.toUpperCase();
  const codeGroups = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
  if (codeGroups === null) {
    return false;
  }
  const rearrangedCode = codeGroups[3] + codeGroups[1] + codeGroups[2];
  const code = rearrangedCode.replace(/[A-Z]/g, character => character.charCodeAt(0) - 55);
  let pos = 9;
  let fragment = code.slice(0, pos);
  let N = fragment;
  let result = parseInt(N, 10) % MOD;
  while (pos < code.length) {
    fragment = code.slice(pos, pos + 7);
    N = result + fragment;
    result = parseInt(N, 10) % MOD;
    pos += 7;
  }
  return Number(result) === 1;
};
