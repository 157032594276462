import Vue from 'vue';
import VeeValidate from 'vee-validate';
import i18n from '@/plugins/i18n';
import * as validators from '@/plugins/validators';
import enLocale from '@/locale/index-en';


VeeValidate.Validator.extend('iban', validators.ibanValidator);

Vue.use(VeeValidate, {
  i18n,
  // i18nRootKey: 'validations', -> not used
  // setup en dictionary
  dictionary: enLocale.validations,
  events: 'change',
});
