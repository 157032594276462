// route names
export const names = {
  not_found: 'NotFound',
  server_error: 'serverError',
};

export default [
  {
    path: '/error',
    name: names.server_error,
    component: () => import(/* webpackChunkName: "share" */ '@/share/Pages/ServerError'),
  },
  // catch all route - must be last
  {
    path: '*',
    name: names.not_found,
    component: () => import(/* webpackChunkName: "share" */ '@/share/Pages/NotFound'),
  },


];
