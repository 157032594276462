/* eslint-disable no-shadow */
import * as types from './types';
import { generalService } from '@/service';

const state = {
  availableCurrencies: ['HRK', 'EUR'],
  activeCurrency: window.localStorage.getItem('currency'),
  defaultCurrency: 'EUR',
  HRK: {
    name: 'HRK',
    code: 'HRK',
    exchangeRate: 1,
  },
  EUR: {
    name: 'EUR',
    code: 'EUR',
    exchangeRate: null,
  },
};

const getters = {
  activeCurrency: (state) => {
    if (state.activeCurrency && state.availableCurrencies.includes(state.activeCurrency)) {
      return state.activeCurrency;
    }
    return state.defaultCurrency;
  },
  exchangeList: (state, getters) => state[getters.activeCurrency],
};

const mutations = {
  [types.SET_NEW_CURRENCY](state, currency) {
    state.activeCurrency = currency;
    window.localStorage.setItem('currency', currency);
  },
  [types.SET_EXCHANGE_RATE](state, payload) {
    payload.exchangeRate = payload.exchangeRate.replace(',', '.');
    payload.exchangeRate = Number(payload.exchangeRate);
    state[payload.currency].exchangeRate = payload.exchangeRate;
  },
};

const actions = {
  [types.CHANGE_CURRENCY]({ commit, dispatch }, currency) {
    commit(types.SET_NEW_CURRENCY, currency);
    if (state[currency].exchangeRate === null) {
      dispatch(types.FETCH_EXCHANGE_RATE, currency);
    }
  },
  [types.FETCH_EXCHANGE_RATE]({ commit }, currency) {
    generalService.getExchangeList(currency).then((r) => {
      commit(types.SET_EXCHANGE_RATE, {
        currency,
        exchangeRate: 7.53450,
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
