import HttpCommon from './HttpCommon';

export default class BookingService extends HttpCommon {
  fetch = serviceId => super.fetch(`/booking/request/${serviceId}`);

  save = (serviceId, request) => super.post(`/booking/request/${serviceId}`, request);

  saveFirst = (serviceId, request) => super.post(`/booking/request/${serviceId}/first`, request);

  saveSecond = (serviceId, request) => super.post(`/booking/request/${serviceId}/second`, request);

  preparePayment = (serviceId, error) => super.fetch(`/booking/request/${serviceId}/prepare-payment?error=${error}`);
}
