<template>
  <div id="app">
    <notification />
    <loader />
    <!-- $route.path instead? Query part problem -->
    <!-- <router-view :key="removeHashPart($route.fullPath)" />-->
    <router-view :key="$route.path" />
  </div>
</template>

<script>

  import { mapGetters } from 'vuex';
  import Loader from '@/share/Components/TheLoader';

  export default {
    name: 'App',
    components: {
      Notification: () => import(/* webpackPrefetch: true */ '@/share/Components/Messages/Notification'),
      Loader,
    },
    computed: {
      ...mapGetters('currencies', {
        exchangeList: 'exchangeList',
      }),
    },
    created() {
      if (this.exchangeList.exchangeRate === null) {
        this.$store.dispatch('currencies/FETCH_EXCHANGE_RATE', this.exchangeList.name);
      }
    },
    /* methods: {
      removeHashPart(fullPath) {
        return fullPath.replace(/#[a-zA-Z]+/, '');
      },
    }, */
  };
</script>

<style src="./assets/styles/main.scss" lang="scss"></style>
