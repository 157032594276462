/* eslint-disable no-shadow */
import * as types from './types';

/**
 * Reservations module: fetches number of reservations in "pending" state (Provider)
 * @type {{pendingReservationsNum: number, pendingReservationsEventSource: null}}
 */
const state = {
  pendingReservationsNum: 0,
  pendingReservationsEventSource: null,
};

const getters = {};

const mutations = {
  [types.SET_PENDING_RESERVATIONS_SOURCE](state) {
    const PENDING_RESERVATIONS_COUNTER_PATH = '/api/owner/reservations/pending/counter';
    const EVENT_NAME = 'pending-reservation-count';

    state.pendingReservationsEventSource = new EventSource(PENDING_RESERVATIONS_COUNTER_PATH);

    state.pendingReservationsEventSource.addEventListener('open', () => {
      console.log(`Connection open for: ${EVENT_NAME}`);
    });
    state.pendingReservationsEventSource.addEventListener('error', () => {
      console.log(`Connection closed for: ${EVENT_NAME}`);
    });

    state.pendingReservationsEventSource.addEventListener(EVENT_NAME, (ev) => {
      this.commit('reservations/changeCounter', Number(ev.data));
    });
  },
  [types.DECREMENT_RESERVATION_COUNTER](state) {
    if (state.pendingReservationsNum > 0) {
      state.pendingReservationsNum -= 1;
    }
  },
  changeCounter(state, value) {
    if (value !== state.pendingReservationsNum) {
      state.pendingReservationsNum = value;
    }

  }
};

const actions = {
  [types.INIT_PENDING_RESERVATIONS_SOURCE]({commit, state}) {
    if (window.EventSource) {
      if (state.pendingReservationsEventSource) {
        // already set - return
        return;
      }
      commit(types.SET_PENDING_RESERVATIONS_SOURCE);
    } else {
      console.log('EventSource not supported by this browser!');
      // to do: xhr polling
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
