import api from './axios-api';
import { CLEAR_AUTH } from '@/store/modules/auth/types';

export default function (store, router) {
  /** *******
   * Helper functions
   ***** */


  function redirectToLogin(origRequestUrl = '') {
    // api calls with query param 'no-redirect' will not be redirected
    if (origRequestUrl.indexOf('no-redirect') > -1) {
      return;
    }
    router.push({
      name: 'login',
      query: { redirect: true },
    });
  }

  // wrap response
  function wrapResponse(response) {
    const wrappedResponse = {
      status: response.status,
      statusText: response.statusText,
      config: response.config,
      headers: response.headers,
      data: response.data,
      hasErrorCode(errorCode) {
        return this.data && 'code' in this.data && this.data.code === errorCode;
      },
    };
    // wrappedResponse.isSuccess = response.status >= 200 && response.status < 300;
    wrappedResponse.isOK = response.status === 200;
    wrappedResponse.isCreated = response.status === 201;
    wrappedResponse.isBadRequest = response.status === 400;
    wrappedResponse.isForbidden = response.status === 403;
    wrappedResponse.isUnauthorized = response.status === 401;
    wrappedResponse.isNotFound = response.status === 404;
    wrappedResponse.isUnprocessable = response.status === 422;
    wrappedResponse.isConflict = response.status === 409;
    wrappedResponse.isServerError = response.status >= 500;
    // wrappedResponse.isConnectionProblem = response.status === 0;

    return wrappedResponse;
  }

  /** *****
   *  Request interceptor
   ******* */
  api.interceptors.request.use((request) => {
    // add token if user is authenticated
    const { token } = store.state.auth;
    if (token) {
      // eslint-disable-next-line no-param-reassign
      request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
  }, (error) => {
    // Something happened in setting up the request that triggered an Error
    console.log('Something happened in setting up the request:', error.message);
    return Promise.reject(error);
  });


  /** *****
   *  Response interceptor
   ******* */
  api.interceptors.response.use(response => wrapResponse(response),
    (error) => {
      if (error.response) {
        // The request was made and the server responded (status code falls out of the range of 2xx)

        const wResponse = wrapResponse(error.response);


        /**
         * Redirect to login for Unauthorized and Forbidden
         *  Forbidden - user is logged in but doesn't have privileges to do this action
         *  Unauthorized - user is NOT logged in (token not provided or invalid token),
         *  action requires authorization
         */
        if (wResponse.isUnauthorized || wResponse.isForbidden) {
          store.commit(`auth/${CLEAR_AUTH}`);
          redirectToLogin();
        }

        /*
          Redirect to 404 page if no response from backend
         */
        // + handling for each request instead of globally
        /* if (wResponse.isNotFound) {
          // replace current location entry in history with 404
          router.replace({ name: '404' });
          store.dispatch(actions.LOADER, false);
        } */

        /*
          Redirect to serverError page if something is wrong with server
        */

        // TODO: maknuti? Redirekt nije više potreban pošto nema "public" stranica
        // TODO: kod grešaka u "dashboardu" generirati error notifikacije?
        if (wResponse.isServerError) {
          console.error(error.response);
          router.push({ name: 'serverError' });
        }


        // if there are validation errors save them to store
        /* if (wResponse.isBadRequest && data.code === VALIDATION_ERROR_CODE) {
        store.dispatch(SET_MESSAGES, {
          type: 'validation',
          messages: wResponse.data.validationErrors,
        });
      } */
        // reject with wrapped response
        return Promise.reject(wResponse);
      }

      if (error.request) {
        // The request was made but no response was received
        // (request is an instance of XMLHttpRequest)
        // server is down or internet connection problem
        console.log(error.message);
      }
      return Promise.reject(error);
    });
}
