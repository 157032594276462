export const MODULE_NAME = 'service';

// mutations
export const SET_DISPLAYED_SERVICE = 'setDisplayedService';

export const SET_SERVICE_PHOTO_GALLERY = 'setServicePhotoGallery';

export const SET_BRAND_DETAILS = 'setBrandDetails';


// actions
export const DISPLAY_SERVICE = 'displayService';


// callers
export const DISPLAY_SERVICE_CALL = `${MODULE_NAME}/${DISPLAY_SERVICE}`;

export const SET_SERVICE_PHOTO_GALLERY_CALL = `${MODULE_NAME}/${SET_SERVICE_PHOTO_GALLERY}`;

export const SET_BRAND_DETAILS_CALL = `${MODULE_NAME}/${SET_BRAND_DETAILS}`;
