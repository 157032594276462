import api from '@/plugins/axios-api';
import GeneralService from './generalService';
import UserService from './userService';
import OwnerService from './ownerService';
import AuthService from './authService';
import Helpers from './helpers';
import CustomerService from './CustomerService';
import BookingService from './bookingService';


export const generalService = new GeneralService(api);
export const userService = new UserService(api);
export const ownerService = new OwnerService(api);
export const authService = new AuthService(api);
export const helpers = new Helpers();
export const customerService = new CustomerService(api);
export const bookingService = new BookingService(api);
