import store from '@/store';
import roles from '@/routes/userRoles';
import loginRoutes from '@/routes/defaultPages';
import { userService } from '@/service';
import { names } from '@/routes/public/public.routes';
import {
  SET_AUTH,
  CLEAR_AUTH,
} from '@/store/modules/auth/types';

export default [
  {
    path: '/login-process',
    name: 'loginProcess',
    beforeEnter: (to, from, next) => {
      const userInfo = store.getters['auth/userInfo'];

      let targetRoute = loginRoutes.CUSTOMER;
      if (userInfo.roles.indexOf(roles.PROVIDER) > -1) {
        targetRoute = loginRoutes.PROVIDER;
      }
      // Redirect to the originally requested page (page before redirect to the login occurred)
      const previousPath = store.state.route.from?.fullPath;
      if (from.query.redirect && previousPath && previousPath !== '/') {
        next(previousPath);
      }
      // if it is first login go to specific page; otherwise on default route (for role) page
      if (from.query.firstLogin) {
        next({ name: targetRoute.firstLogin });
      } else {
        next({ name: targetRoute.default });
      }
    },
  },
  {
    path: '/logout',
    name: 'logout',
    beforeEnter: async (to, from, next) => {
      await store.dispatch('auth/DEAUTHENTICATE');
      next({ name: names.login });
    },
  },

  {
    path: '/oauth',
    name: 'oauth',
    beforeEnter: (to, from, next) => {
      const oauthToken = to.query['oauth2-token'];
      if (oauthToken) {
        userService.authenticateWithOauthToken(oauthToken)
          .then((response) => {
            store.commit(
              `auth/${SET_AUTH}`, response.data,
            );
            // redirect
            let targetRoute = loginRoutes.CUSTOMER;
            if (response.data.userInfo.roles.indexOf(roles.PROVIDER) > -1) {
              targetRoute = loginRoutes.PROVIDER;
            }

            next({ name: targetRoute.default });
          }).catch(() => {
            store.commit(`auth/${CLEAR_AUTH}`);
          });
      } else {
        next({
          path: '/login',
          query: to.query,
        });
      }
    },
  },
];
