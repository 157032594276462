// examples: https://github.com/vuejs/vue-router/blob/dev/examples/scroll-behavior/app.js

// scrollBehavior:
// - only available in html5 history mode
// - defaults to top position
// - will retain current scroll position if route has meta field preserveScroll
export default function scrollBehavior(to, from, savedPosition) {
  if (savedPosition) {
    // savedPosition is only available for "popstate navigations" (back/forward buttons).
    return savedPosition;
  }
  const position = {};
  // scroll to anchor by returning the selector
  if (to.hash) {
    position.selector = to.hash;

    if (document.querySelector(to.hash)) {
      return position;
    }

    // if the returned position is falsy or an empty object, (no hash/id on page)
    // will retain current scroll position.
    return false;
  }

  position.x = 0;
  position.y = 0;

  return (to.matched.some(m => m.meta.preserveScroll)) ? false : position;
}
