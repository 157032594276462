import store from '@/store';
import { INIT_PENDING_RESERVATIONS_SOURCE } from '@/store/modules/reservations/types';
import roles from '@/routes/userRoles';

const hasOwnerRole = userInfo => (userInfo != null && userInfo.roles.indexOf(roles.PROVIDER) > -1);
const INIT_PENDING_RESERVATION_SOURCE_CALL = `reservations/${INIT_PENDING_RESERVATIONS_SOURCE}`;

export default (to, from) => {
  if (hasOwnerRole(store.state.auth.userInfo)) {
    // init EventSource - pending reservation count
    store.dispatch(INIT_PENDING_RESERVATION_SOURCE_CALL);
  }
};
