import HttpCommon from './HttpCommon';


export default class GeneralService extends HttpCommon {
  // 1. - service related
  // 13.06.2021 - sve iz dijela 1 za brisanje osim getPhotoGallery
  getServices(page = 0, size) {
    return this.fetch(`/services?size=${size}&page=${page}`);
  }

  getPopularServices(page, size) {
    return this.fetch(`/services/popular?size=${size}&page=${page}`);
  }

  getLatestServices(page, size) {
    return this.fetch(`/services/latest?size=${size}&page=${page}`);
  }

  getServicesByCategorySlug(categorySlug, page, size) {
    return this.fetch(`/services/category/${categorySlug}?size=${size}&page=${page}`);
  }

  getServicesByRegionSlug(slug, page, size) {
    return this.fetch(`/services/region/${slug}?size=${size}&page=${page}`);
  }

  // Koristi se i na fotogaleriji usluge u dashbord-u Branda
  getPhotoGallery(serviceId) {
    return this.fetch(`/services/${serviceId}/photos`);
  }

  getRelatedServices(serviceId) {
    return this.fetch(`/services/${serviceId}/related`);
  }

  getService(serviceId) {
    return this.fetch(`/services/${serviceId}`);
  }

  getBrand(brandId) {
    return this.fetch(`/brands/${brandId}`);
  }

  getBrandServices(brandId)  {
    return this.fetch(`/brands/${brandId}/services`);
  }


  // fetch season for calendar
  getBookingTime(serviceId, from, to) {
    const params = new URLSearchParams();
    params.append('from', from);
    params.append('to', to);
    return this.fetch(`/services/${serviceId}/availability`, params);
  }

  // 2. - get countries, regions, phone examples, types of objects, categories, tags and amenities
  getCountries() {
    return this.fetch('/countries');
  }

  getRegions() {
    return this.fetch('/regions');
  }

  // ne koristi se
  getSortedRegions() {
    return this.fetch('/regions/sorted');
  }

  getPhoneExamples(code) {
    return this.fetch(`/phone/examples?code=${code}`);
  }

  getCallingCodes() {
    return this.fetch('/phone/supported-calling-codes');
  }


  getBrandTypes() {
    return this.fetch('/owner/brands/types');
  }

  getCategories() {
    return this.fetch('/categories');
  }

  getSortedCategories() {
    return this.fetch('/categories/sorted');
  }

  getCategoryTags(categoryId) {
    return this.fetch(`/tags/${categoryId}`);
  }

  getAmenities() {
    return this.fetch('/amenities');
  }

  // 3. - get cancellation policy for service, price types and difficulties for offer
  getServiceAndOfferResources() {
    return this.fetch('/owner/services/resources');
  }

  getLanguages() {
    return this.fetch('/languages');
  }

  // 4. - other pages
  // 13.06.2021 - sve iz dijela 4 za brisanje osim cooperation i getExchangeList
  helpMe(helpData) {
    return this.post('/help', helpData);
  }

  cooperation(cooperationData) {
    return this.post('/cooperation', cooperationData);
  }

  sendDemand(demand) {
    return this.post('/demand', demand);
  }

  christmasActionDemand(christmasActionData) {
    return this.post('/christmas-action', christmasActionData);
  }

  getExchangeList(valuta) {
    const params = new URLSearchParams();
    params.append('valuta', valuta);
    return this.fetch('/hnb/tecaj', params);
  }
}
