import HttpCommon from './HttpCommon';

export default class UserService extends HttpCommon {
  // temp. here stay
  createCustomer(customer) {
    return this.post('/registration/customer', customer);
  }

  // 1. - authenticate user
  authenticate(email, password) {
    const params = new URLSearchParams();
    params.append('email', email);
    params.append('password', password);
    return this.post('/login', params);
  }

  authenticateWithOauthToken(token) {
    const params = new URLSearchParams();
    params.append('oauth2-token', token);
    return this.fetch('/oauth-token', params);
  }

  // 2. - display or update User Info
  getInfo() {
    return this.fetch('/account/me?no-redirect');
  }

  getUserSettings() {
    return this.fetch('/account/settings/me');
  }

  updatePlatformSettings(settings) {
    return this.update('/account/settings/platform', settings);
  }

  updateInfo(user) {
    return this.update('/account/settings/me', user);
  }

  // 3. - update User Password
  changePassword(oldPassword, newPassword) {
    const params = new URLSearchParams();
    params.append('password', oldPassword);
    params.append('newPassword', newPassword);
    return this.post('/account/password-change', params);
  }

  // 4. - save or delete Profile Photo
  profilePhotoUpload(profilePicture) {
    const photo = new FormData();
    photo.append('photo', profilePicture);
    return this.post('/account/photo', photo);
  }

  profilePhotoDelete() {
    return this.update('/account/account-photo');
  }

  addCustomerRole() {
    return this.update('/account/role/customer');
  }

  requestPasswordReset = (details) => {
    const data = new FormData();
    data.append('email', details.email);
    data.append('reCaptchaToken', details.reCaptchaToken);
    return this.post('/password-reset/init', data);
  }

  forgetMe = () => this.update('/account/forget-me');

  resetPassword = keyAndPassword => this.post('/password-reset/complete', keyAndPassword);
}
