class HttpCommon {
  constructor(api) {
    this.api = api;
  }

  // set header for all requests
  setHeader(header, value) {
    this.api.default.common[header] = value;
  }

  fetch(url, data = null) {
    return this.api.get(url, { params: data });
  }

  post(url, data) {
    return this.api.post(url, data);
  }

  update(url, data) {
    return this.api.put(url, data);
  }

  delete(url, id) {
    return this.api.delete(url, { params: { id } });
  }
}

export default HttpCommon;
