export default {
  fullname: 'your first and last name',
  'service-name': 'sevice name',
  'num-people': 'number of people',
  email: 'your email address',
  'new-password': 'new password',
  'confirm-password': 'your password again',
  'old-password': 'your old password',
  address: 'the address',
  'address-pos': 'a valid address',
  'post-number': 'the zip code',
  city: 'the city/town name',
  'contact-number': 'your contact number',
  vatin: 'company’s personal number',
  'account-owner': 'the account owner’s name',
  iban: 'IBAN',
  demand: ' the details of the service you want',
  'company-name': 'your company name',
  type: 'your primary activity',
  'brand-name': 'your brand name',
  'activity-description': 'the activity description',
  'service-title': 'the service name',
  'service-description': 'the description for this service',
  included: 'the element',
  statement: 'the statement about why you are specific',
  microlocation: 'the statement about why the micro-location is specific',
  'service-minimum-people': 'the minimum number of people',
  'service-maximum-people': 'the maximum number of people',
  response: 'the response time',
  'offer-title': 'the offer name',
  'offer-price': 'the price',
  'season-name': 'the season name',
};
