<template>
  <div class="l-wrap" v-show="isActive">
    <div class="loader"></div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        isActive: false,
      };
    },
    computed: {
      loaderDisplay() {
        return this.$store.state.loaderDisplay;
      },
    },
    watch: {
      loaderDisplay() {
        this.isActive = !this.isActive;
      },
    },
  };
</script>

<style lang="sass" scoped>

  @import "../../assets/styles/vars"

  .l-wrap
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    position: absolute
    width: 100vw
    height: 100vh
    background-color: rgba(255,255,255,0.6)
    z-index: 10
  .loader
    border: 16px solid $green
    border-top: 16px solid $dark-green
    border-radius: 50%
    width: 20px
    height: 20px
    margin: 0 auto
    animation: spin 1s linear infinite
    @keyframes  spin
      0%
        transform: rotate(0deg)
      100%
        transform: rotate(360deg)
</style>
