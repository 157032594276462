/**
 * Default landing page on successful login for diff roles
 */
import { names as providerRoutes } from '@/routes/provider/provider.routes';
import { names as providerDashboardRoutes } from '@/routes/provider/dashboard.routes';
import { names as customerRoutes } from '@/routes/customer.routes';


export default {
  PROVIDER: {
    home: providerDashboardRoutes.recent_activity,
    firstLogin: providerRoutes.welcome,
    default: providerDashboardRoutes.recent_activity,
  },
  CUSTOMER: {
    home: customerRoutes.purchases,
    firstLogin: customerRoutes.personal_info_customer,
    default: customerRoutes.purchases,
  },
};
