import store from '@/store';

const accomodationNavbarRegisterLink = [
  {
    translation: 'button.accomodation-registration',
    classes: 'navbar-link cta-button-small',
    to: '#register',
  },
];
const serviceProviderNavbarRegisterLink = [
  {
    translation: 'button.service-provider-registration',
    classes: 'navbar-link cta-button-small',
    to: '#register',
  },
];

// route names
export const names = {
  about_us: 'aboutUs',
  categories: 'displayCategories',
  category_services: 'servicesByCategory',
  customer_registration: 'customerRegister',
  accomodation_registration: 'accomodationRegister',
  help: 'help',
  home: 'home',
  latest_services: 'servicesById',
  login: 'login',
  on_demand_service: 'OnDemand',
  password_reset: 'passwordReset',
  popular_services: 'servicesByPopularity',
  privacy_policy: 'privacyPolicy',
  provider_registration: 'partners',
  regions: 'displayRegions',
  region_services: 'servicesByRegion',
  service_display: 'displayService',
  brand_display: 'displayBrand',
  terms_and_conditions: 'termsAndConditions',
  travel_terms: 'travelTerms',
  cookie_policy: 'cookiePolicy',
  bozic: 'bozicAkcija',
};

// routes
export default [
  {
    path: '/',
    redirect: names.login,
    /* name: names.home,
    component: () => import(/!* webpackChunkName: "public" *!/ '@/modules/Public/Pages/Home'),
    meta: {
      navbar: {
        sticky: true,
      },
    }, */
  },
  // service, brand, services, regions and categories
  {
    path: '/brand/:slug/:brandId',
    redirect: names.login,
    /* component: () => import(/!* webpackChunkName: "share" *!/ '@/share/Layouts/BaseLayout'),
    children: [
      {
        path: '',
        name: names.brand_display,
        component: () => import(/!* webpackChunkName: "public" *!/ '@/modules/Public/Pages/DisplayBrand'),
        meta: {
          displayCategories: true,
        },
      },
    ], */
  },
  {
    path: '/:regionSlug/:slug/:serviceId',
    redirect: names.login,
  /*  component: () => import(/!* webpackChunkName: "share" *!/ '@/share/Layouts/BaseLayout'),
    children: [
      {
        path: '',
        name: names.service_display,
        component: () => import(/!* webpackChunkName: "public" *!/ '@/modules/Public/Pages/DisplayService'),
        meta: {
          displayCategories: false,
        },
      },
    ], */
  },
  {
    path: '/things-to-do',
    redirect: names.login,
    /* component: () => import(/!* webpackChunkName: "share" *!/ '@/share/Layouts/BaseLayout'),
    children: [
      {
        path: '',
        name: names.popular_services,
        component: () => import(/!* webpackChunkName: "public" *!/ '@/modules/Public/Pages/DisplayServices'),
        meta: {
          displayCategories: true,
        },
      },
    ], */
  },
  {
    path: '/most-recent',
    redirect: names.login,
    /* component: () => import(/!* webpackChunkName: "share" *!/ '@/share/Layouts/BaseLayout'),
    children: [
      {
        path: '',
        name: names.latest_services,
        component: () => import(/!* webpackChunkName: "public" *!/ '@/modules/Public/Pages/DisplayServices'),
        meta: {
          displayCategories: true,
        },
      },
    ], */
  },
  {
    path: '/category/:slug',
    redirect: names.login,
    /* component: () => import(/!* webpackChunkName: "share" *!/ '@/share/Layouts/BaseLayout'),
    children: [
      {
        path: '',
        name: names.category_services,
        component: () => import(/!* webpackChunkName: "public" *!/ '@/modules/Public/Pages/DisplayServices'),
        meta: {
          displayCategories: true,
        },
      },
    ], */
  },
  {
    path: '/destination/:slug',
    redirect: names.login,
    /* component: () => import(/!* webpackChunkName: "share" *!/ '@/share/Layouts/BaseLayout'),
    children: [
      {
        path: '',
        name: names.region_services,
        component: () => import(/!* webpackChunkName: "public" *!/ '@/modules/Public/Pages/DisplayServices'),
        meta: {
          displayCategories: true,
        },
      },
    ], */
  },
  {
    path: '/destinations',
    redirect: names.login,
    /* component: () => import(/!* webpackChunkName: "share" *!/ '@/share/Layouts/BaseLayout'),
    children: [
      {
        path: '',
        name: names.regions,
        component: () => import(/!* webpackChunkName: "public" *!/ '@/modules/Public/Pages/DisplayRegions'),
        meta: {
          displayCategories: true,
        },
      },
    ], */
  },
  {
    path: '/categories',
    redirect: names.login,
    /* component: () => import(/!* webpackChunkName: "share" *!/ '@/share/Layouts/BaseLayout'),
    children: [
      {
        path: '',
        name: names.categories,
        component: () => import(/!* webpackChunkName: "public" *!/ '@/modules/Public/Pages/DisplayCategories'),
        meta: {
          displayCategories: true,
        },
      },
    ], */
  },
  // registration for customers and tourism object
  {
    path: '/register-customers',
    redirect: {
      name: names.accomodation_registration,
    },
  },
  {
    path: '/register-accomodation',
    component: () => import(/* webpackChunkName: "share" */ '@/share/Layouts/BaseLayout'),
    children: [
      {
        path: '',
        name: names.accomodation_registration,
        component: () => import(/* webpackChunkName: "public" */ '@/modules/Public/Pages/CustomerRegistration'),
        meta: {
          navbar: {
            sticky: true,
            links: accomodationNavbarRegisterLink,
          },
        },
      },
    ],
  },

  // not used
  /* {
    path: '/tourism-objects',
    component: () => import(/!* webpackChunkName: "share" *!/ '@/share/Layouts/BaseLayout'),
    children: [
      {
        path: '',
        name: 'registerTourismObjects',
        component: loadPage('TourismObjectRegistration'),
        meta: {
          navbar: {
            sticky: true,
            links: navbarRegisterLink,
          },
        },
      },
    ],
  }, */
  // general forms: login, help and demand
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "share" */ '@/share/Layouts/CentralizeBodyContent'),
    children: [
      {
        name: names.login,
        path: '',
        component: () => import(/* webpackChunkName: "public" */ '@/modules/Public/Pages/Login'),
        beforeEnter: (to, from, next) => {
          const isAuthenticated = store.getters['auth/isAuthenticated'];
          if (isAuthenticated) { next({ name: 'loginProcess' }); }
          next();
        },
      },
    ],
  },
  {
    path: '/help',
    redirect: names.login,
    /* component: () => import(/!* webpackChunkName: "share" *!/ '@/share/Layouts/CentralizeBodyContent'),
    children: [
      {
        name: names.help,
        path: '',
        component: () => import(/!* webpackChunkName: "public" *!/ '@/modules/Public/Pages/Help'),
        meta: {
          navbar: {
            sticky: true,
          },
        },
      },
    ], */
  },
  {
    path: '/custom-private-tours',
    redirect: names.login,
    /* component: () => import(/!* webpackChunkName: "share" *!/ '@/share/Layouts/CentralizeBodyContent'),
    children: [
      {
        name: names.on_demand_service,
        path: '',
        component: () => import(/!* webpackChunkName: "public" *!/ '@/modules/Public/Pages/OnDemand'),
      },
    ], */
  },
  // general pages: privacy, terms etc
  {
    path: '/privacy-policy',
    redirect: names.login,
    /* component: () => import(/!* webpackChunkName: "share" *!/ '@/share/Layouts/CentralizeBodyContent'),
    children: [
      {
        name: names.privacy_policy,
        path: '',
        component: () => import(/!* webpackChunkName: "public" *!/ '@/modules/Public/Pages/PrivacyPolicy'),
        meta: {
          navbar: {
            sticky: true,
          },
        },
      },
    ], */
  },
  {
    path: '/cookie-policy',
    redirect: names.login,
    /* component: () => import(/!* webpackChunkName: "share" *!/ '@/share/Layouts/CentralizeBodyContent'),
    children: [
      {
        name: names.cookie_policy,
        path: '',
        component: () => import(/!* webpackChunkName: "public" *!/ '@/modules/Public/Pages/CookiePolicy'),
        meta: {
          navbar: {
            sticky: true,
          },
        },
      },
    ], */
  },
  {
    path: '/site-terms-conditions',
    redirect: names.login,
    /* component: () => import(/!* webpackChunkName: "share" *!/ '@/share/Layouts/CentralizeBodyContent'),
    children: [
      {
        name: names.terms_and_conditions,
        path: '',
        component: () => import(/!* webpackChunkName: "public" *!/ '@/modules/Public/Pages/TermsAndConditions'),
        meta: {
          navbar: {
            sticky: true,
          },
        },
      },
    ], */
  },
  {
    path: '/travel-conditions',
    redirect: names.login,
    /* component: () => import(/!* webpackChunkName: "share" *!/ '@/share/Layouts/CentralizeBodyContent'),
    children: [
      {
        name: names.travel_terms,
        path: '',
        component: () => import(/!* webpackChunkName: "public" *!/ '@/modules/Public/Pages/TravelTerms'),
        meta: {
          navbar: {
            sticky: true,
          },
        },
      },
    ], */
  },
  {
    path: '/about-us',
    redirect: names.login,
    /* name: names.about_us,
    component: () => import(/!* webpackChunkName: "public" *!/ '@/modules/Public/Pages/AboutUs'),
    meta: {
      navbar: {
        sticky: true,
      },
    }, */
  },
  // password reset
  {
    path: '/password-reset',
    name: names.password_reset,
    component: () => import(/* webpackChunkName: "share" */'@/share/Pages/PasswordReset'),
  },
  // provider registration
  {
    path: '/register-partners',
    name: names.provider_registration,
    component: () => import(/* webpackChunkName: "public" */ '@/modules/Public/Pages/ServiceProviderRegistration'),
    meta: {
      navbar: {
        sticky: true,
        links: serviceProviderNavbarRegisterLink,
      },
    },
  },
  {
    path: '/bozic-akcija',
    redirect: names.login,
    /* component: () => import(/!* webpackChunkName: "share" *!/ '@/share/Layouts/CentralizeBodyContent'),
    children: [
      {
        name: names.bozic,
        path: '',
        component: () => import(/!* webpackChunkName: "public" *!/ '@/modules/Public/Pages/ChristmasAction'),
        meta: {
          navbar: {
            sticky: true,
          },
        },
      },
    ], */
  },
];
