import HttpCommon from './HttpCommon';

export default class OwnerService extends HttpCommon {
  // create Owner
  createOwner(owner) {
    return this.post('/register/owner', owner);
  }

  // 1. - add, display or update Company
  saveOrUpdateCompany(company) {
    const url = '/owner/company';
    return company.isNew ? this.post(url, company) : this.update(url, company);
  }

  getCompany() {
    return this.fetch('/owner/company');
  }

  // 2. - add, display, update or delete Brand(s)
  saveBrand(brand) {
    return this.post('/owner/brands', brand);
  }

  getBrands() {
    return this.fetch('/owner/brands');
  }

  getBrand(id) {
    return this.fetch(`/owner/brands/${id}`);
  }

  updateBrand(id, brand) {
    return this.update(`/owner/brands/${id}`, brand);
  }

  deleteBrand(id) {
    return this.delete(`/owner/brands/${id}`);
  }

  // 3.a - add, display, update or delete Service(s)
  getLocation(id) {
    return this.fetch(`/owner/brands/${id}/location`);
  }

  saveService(service, brandId) {
    return this.post(`/owner/brands/${brandId}/services`, service);
  }

  getServices(brandId) {
    return this.fetch(`/owner/brands/${brandId}/services`);
  }

  getService(id) {
    return this.fetch(`/owner/services/${id}`);
  }

  displayServicePreview(id) {
    return this.fetch(`/owner/services/${id}/preview`);
  }

  updateService(serviceId, service) {
    return this.update(`/owner/services/${serviceId}`, service);
  }

  deleteService(serviceId) {
    return this.delete(`/owner/services/${serviceId}`);
  }

  // 4.a - add, display, update or delete Season(s)
  addSeason(serviceId, season) {
    return this.post(`/owner/services/${serviceId}/season`, season);
  }

  getAllSeasons(serviceId, itemsPerPage, page) {
    return this.fetch(`/owner/services/${serviceId}/season?sort=to,desc&size=${itemsPerPage}&page=${page}`);
  }

  updateSeason(seasonId, season) {
    return this.update(`/owner/season/${seasonId}`, season);
  }

  deleteSeason(seasonId) {
    return this.delete(`/owner/season/${seasonId}`);
  }

  // 4.b - add, display or delete Working Time(s)
  addWorkingTime(seasonId, workingTime) {
    return this.post(`/owner/season/${seasonId}/booking-time`, workingTime);
  }

  getWorkingTime(seasonId) {
    return this.fetch(`/owner/season/${seasonId}/booking-time`);
  }

  deleteWorkingTime(seasonId, workingTimeId) {
    return this.delete(`/owner/season/${seasonId}/booking-time/${workingTimeId}`);
  }

  // 4.c - add, display or delete Unavailable Date(s)
  addUnavailableDate(seasonId, date) {
    const params = new URLSearchParams();
    params.append('date', date);
    return this.post(`/owner/season/${seasonId}/unavailable-dates`, params);
  }

  getUnavailableDates(seasonId) {
    return this.fetch(`/owner/season/${seasonId}/unavailable-dates`);
  }

  deleteUnavailableDate(seasonId, date) {
    return this.api.delete(`/owner/season/${seasonId}/unavailable-dates/${date}`);
  }

  // 5. - add, display, update or delete Offer(s)
  saveOffer(serviceId, offer) {
    return this.post(`/owner/services/${serviceId}/variations`, offer);
  }

  getOffers(serviceId) {
    return this.fetch(`/owner/services/${serviceId}/variations`);
  }

  updateOffer(serviceId, offerId, offer) {
    return this.update(`/owner/services/${serviceId}/variations/${offerId}`, offer);
  }

  deleteOffer(serviceId, offerId) {
    return this.delete(`/owner/services/${serviceId}/variations/${offerId}`);
  }

  // 6. - add or delete Photos and Descriptions in Photo Gallery
  addPhotoInPhotoGallery(serviceId, photo) {
    const photoInGallery = new FormData();
    photoInGallery.append('photo', photo);
    return this.post(`/owner/services/${serviceId}/photos`, photoInGallery);
  }

  markPhotoAsPrimary(serviceId, photoName) {
    return this.api.patch(`/owner/services/${serviceId}/photos/${photoName}`);
  }

  addDescriptionToPhoto(serviceId, photoName, description) {
    const params = new URLSearchParams();
    params.append('photoName', photoName);
    params.append('description', description);
    return this.api.patch(`/owner/services/${serviceId}/photos`, params);
  }

  deletePhotoFromPhotoGallery(serviceId, photoName) {
    return this.delete(`/owner/services/${serviceId}/photos/${photoName}`);
  }

  /* 6. - get Reservations data for:
      a) statistics
      b) today reservations
      c) reservations status: pending, accepted, rejected/canceled
   */

  getReservationStatistics(brandId) {
    if (brandId) {
      return this.fetch(`/owner/reservations/revenue?brand=${brandId}`);
    }
    return this.fetch('/owner/reservations/revenue');
  }

  getTodayAcceptedReservations() {
    return this.fetch('/owner/reservations/accepted/today');
  }

  getPendingReservations(itemsPerPage, page) {
    return this.fetch(`/owner/reservations/pending/future?size=${itemsPerPage}&page=${page}`);
  }

  getAcceptedFutureReservations(itemsPerPage, page) {
    return this.fetch(`/owner/reservations/accepted/future?size=${itemsPerPage}&page=${page}`);
  }

  getAcceptedPastReservations(itemsPerPage, page) {
    return this.fetch(`/owner/reservations/accepted/past?size=${itemsPerPage}&page=${page}`);
  }

  getAcceptedReservations() {
    return this.fetch('/owner/reservations/accepted');
  }

  getCancelledReservations(itemsPerPage, page) {
    return this.fetch(`/owner/reservations/other?size=${itemsPerPage}&page=${page}`);
  }

  // 7. change reservation status

  acceptReservation(id) {
    return this.update(`/owner/reservations/${id}/acceptance`);
  }

  rejectReservation(id, reason) {
    const params = new URLSearchParams();
    if (reason) {
      params.append('reason', reason);
    }

    return this.update(`/owner/reservations/${id}/rejection`, params);
  }

  getRejectionReasons() {
    return this.fetch('/owner/reservations/rejection-reasons');
  }

  generateContent(url, keywords) {
    return this.fetch(`/owner/services/summary?url=${url}&keywords=${keywords}`);
  }
}
