import roles from '@/routes/userRoles';


export const names = {
  add_additional_brand: 'additionalBrand',
  add_additional_offer: 'additionalOffers',
  add_additional_season: 'additionalSeasons',
  add_additional_service: 'additionalService',
  add_additional_photos: 'additionalPhotogallery',
  brands: 'displayBrands',
  cooperation: 'cooperation',
  edit_brand: 'editBrand',
  edit_company: 'editCompany',
  personal_info_provider: 'personalInfoProvider',
  edit_photos: 'editPhotogallery',
  edit_offers: 'editOffers',
  edit_season: 'editSeason',
  edit_service: 'editService',
  recent_activity: 'recentActivity',
  reservations: 'reservations',
  services: 'displayServices',
  statistics: 'statistics',
};

export default [
  {
    path: '/dashboard',
    component: () => import(/* webpackChunkName: "provider" */ '@/modules/ServiceProvider/Layouts/ServiceProviderDashboard.vue'),
    meta: {
      requireRoles: [roles.PROVIDER],
    },
    children: [
      // A. ALL USERS
      // 1. personal info
      {
        path: 'personal-info',
        name: names.personal_info_provider,
        component: () => import(/* webpackChunkName: "share" */ '@/share/Pages/PersonalInfoForm'),
      },
      // B. PROVIDER ROUTES
      // 1. edit company
      {
        path: 'company',
        name: names.edit_company,
        component: () => import(/* webpackChunkName: "provider" */ '@/modules/ServiceProvider/Pages/Forms/Company'),
        meta: {
          requireRoles: [roles.PROVIDER],
          editPart: true,
        },
      },
      // 2. display and edit brands
      {
        path: 'brands',
        name: names.brands,
        component: () => import(/* webpackChunkName: "provider" */ '@/modules/ServiceProvider/Pages/ServiceProviderListOfBrands'),
        meta: {
          requireRoles: [roles.PROVIDER],
        },
      },
      {
        path: 'brand/edit/:id',
        name: names.edit_brand,
        component: () => import(/* webpackChunkName: "provider" */ '@/modules/ServiceProvider/Pages/Forms/Brand'),
        props: true,
        meta: {
          requireRoles: [roles.PROVIDER],
          editPart: true,
        },
      },
      // 3. display and edit services
      {
        path: 'brands/:brandId/services',
        name: names.services,
        component: () => import(/* webpackChunkName: "provider" */ '@/modules/ServiceProvider/Pages/ServiceProviderListOfServices'),
        props: true,
        meta: {
          requireRoles: [roles.PROVIDER],
        },
      },
      {
        path: 'brands/:brandId/services/:serviceId',
        name: names.edit_service,
        component: () => import(/* webpackChunkName: "provider" */ '@/modules/ServiceProvider/Pages/Forms/Service'),
        props: true,
        meta: {
          requireRoles: [roles.PROVIDER],
          editPart: true,
        },
      },
      // 4. display and edit pricings
      {
        path: 'brands/:brandId/services/:serviceId/offers',
        name: names.edit_offers,
        component: () => import(/* webpackChunkName: "provider" */ '@/modules/ServiceProvider/Pages/Forms/Offer/Offer'),
        props: true,
        meta: {
          requireRoles: [roles.PROVIDER],
          editPart: true,
        },
      },
      // 5. display and edit seasons
      {
        path: 'brands/:brandId/services/:serviceId/season',
        name: names.edit_season,
        component: () => import(/* webpackChunkName: "provider" */ '@/modules/ServiceProvider/Pages/Forms/Season/Season'),
        props: true,
        meta: {
          requireRoles: [roles.PROVIDER],
          editPart: true,
        },
      },
      // 6. display and edit photogallery
      {
        path: 'brands/:brandId/services/:serviceId/photogallery',
        name: names.edit_photos,
        component: () => import(/* webpackChunkName: "provider" */ '@/modules/ServiceProvider/Pages/Forms/Photogallery'),
        props: true,
        meta: {
          requireRoles: [roles.PROVIDER],
          editPart: true,
        },
      },
      // 7. cooperation form
      {
        path: 'cooperation',
        name: names.cooperation,
        component: () => import(/* webpackChunkName: "provider" */ '@/modules/ServiceProvider/Pages/ServiceProviderCooperationForm'),
        meta: {
          requireRoles: [roles.PROVIDER],
        },
      },
      // 8. additional add of brand, service, pricings, seasons and photo gallery
      {
        path: 'new-brand',
        name: names.add_additional_brand,
        component: () => import(/* webpackChunkName: "provider" */ '@/modules/ServiceProvider/Pages/Forms/Brand'),
        meta: {
          requireRoles: [roles.PROVIDER],
        },
      },
      {
        path: 'brands/:brandId/service/new',
        name: names.add_additional_service,
        component: () => import(/* webpackChunkName: "provider" */ '@/modules/ServiceProvider/Pages/Forms/Service'),
        props: true,
        meta: {
          requireRoles: [roles.PROVIDER],
        },
      },
      {
        path: 'brands/:brandId/service/:serviceId/offers/new',
        name: names.add_additional_offer,
        component: () => import(/* webpackChunkName: "provider" */ '@/modules/ServiceProvider/Pages/Forms/Offer/Offer'),
        props: true,
        meta: {
          requireRoles: [roles.PROVIDER],
        },
      },
      {
        path: 'brands/:brandId/service/:serviceId/seasons/new',
        name: names.add_additional_season,
        component: () => import(/* webpackChunkName: "provider" */ '@/modules/ServiceProvider/Pages/Forms/Season/Season'),
        props: true,
        meta: {
          requireRoles: [roles.PROVIDER],
        },
      },
      {
        path: 'brands/:brandId/service/:serviceId/photogallery/new',
        name: names.add_additional_photos,
        component: () => import(/* webpackChunkName: "provider" */ '@/modules/ServiceProvider/Pages/Forms/Photogallery'),
        props: true,
        meta: {
          requireRoles: [roles.PROVIDER],
        },
      },
      // 9. display recent activity, reservations, statistics and transactions
      {
        path: 'recent',
        name: names.recent_activity,
        component: () => import(/* webpackChunkName: "provider" */ '@/modules/ServiceProvider/Pages/ServiceProviderRecentActivity'),
        meta: {
          requireRoles: [roles.PROVIDER],
        },
      },
      {
        path: 'reservations',
        name: names.reservations,
        component: () => import(/* webpackChunkName: "provider" */ '@/modules/ServiceProvider/Pages/ServiceProviderReservations'),
        meta: {
          requireRoles: [roles.PROVIDER],
        },
      },
      {
        path: 'statistics',
        name: names.statistics,
        component: () => import(/* webpackChunkName: "provider" */ '@/modules/ServiceProvider/Pages/ServiceProviderStatistics'),
        meta: {
          requireRoles: [roles.PROVIDER],
        },
      },
      /* {
        path: 'transactions',
        name: 'transactions',
        component: loadComponent('Owner/DisplayInfo/Transactions'),
        meta: {
          requireRoles: [roles.PROVIDER],
        },
      }, */
    ],
  },
];
