import roles from '@/routes/userRoles';


export const names = {
  add_brand: 'addBrand',
  add_company: 'addCompany',
  add_photos: 'addPhotogallery',
  add_offer: 'addOffers',
  add_season: 'addSeason',
  add_service: 'addService',
  preview_service: 'servicePreview',
  welcome: 'providerWelcome',
};

export default [
  {
    path: '/add',
    component: () => import(/* webpackChunkName: "share" */ '@/share/Layouts/BaseLayout'),
    meta: {
      navbar: {
        hideMenu: true,
      },
    },
    children: [
      // 1. -  welcome page, add profile photo
      {
        path: 'welcome',
        name: names.welcome,
        component: () => import(/* webpackChunkName: "provider" */ '@/modules/ServiceProvider/Pages/ServiceProviderWelcome'),
        meta: {
          requireRoles: [roles.PROVIDER],
        },
      },
      // 2. - add company, brand, service, pricing, season and photo gallery
      {
        path: 'company',
        name: names.add_company,
        component: () => import(/* webpackChunkName: "provider" */ '@/modules/ServiceProvider/Pages/Forms/Company'),
        meta: {
          requireRoles: [roles.PROVIDER],
        },
      },
      {
        path: 'brand',
        name: names.add_brand,
        component: () => import(/* webpackChunkName: "provider" */ '@/modules/ServiceProvider/Pages/Forms/Brand'),
        meta: {
          requireRoles: [roles.PROVIDER],
        },
      },
      {
        path: ':brandId/service/new',
        name: names.add_service,
        component: () => import(/* webpackChunkName: "provider" */ '@/modules/ServiceProvider/Pages/Forms/Service'),
        props: true,
        meta: {
          requireRoles: [roles.PROVIDER],
        },
      },
      {
        path: ':brandId/service/:serviceId/offers',
        name: names.add_offer,
        component: () => import(/* webpackChunkName: "provider" */ '@/modules/ServiceProvider/Pages/Forms/Offer/Offer'),
        props: true,
        meta: {
          requireRoles: [roles.PROVIDER],
        },
      },
      {
        path: ':brandId/service/:serviceId/seasons',
        name: names.add_season,
        component: () => import(/* webpackChunkName: "provider" */ '@/modules/ServiceProvider/Pages/Forms/Season/Season'),
        props: true,
        meta: {
          requireRoles: [roles.PROVIDER],
        },
      },
      {
        path: ':brandId/service/:serviceId/photogallery',
        name: names.add_photos,
        component: () => import(/* webpackChunkName: "provider" */ '@/modules/ServiceProvider/Pages/Forms/Photogallery'),
        props: true,
        meta: {
          requireRoles: [roles.PROVIDER],
        },
      },
    ],
  },
  //  Service Preview route
  {
    path: '/:brandId/service/:serviceId/preview',
    name: names.preview_service,
    component: () => import(/* webpackChunkName: "provider" */ '@/modules/ServiceProvider/Pages/ServiceProviderServicePreview'),
    props: true,
    meta: {
      requireRoles: [roles.PROVIDER],
    },
  },
];
