/* eslint-disable no-use-before-define */
import axios from 'axios';

const api = axios.create();

/**
 * Basic api config
 */
api.defaults.baseURL = '/api';
api.defaults.headers.common.Accept = 'application/json';
// `validateStatus` defines whether to resolve or reject the promise for a given
// HTTP response status code. If `validateStatus` returns `true` (or is set to `null`
// or `undefined`), the promise will be resolved; otherwise, the promise will be
// rejected.
api.defaults.validateStatus = status => status >= 200 && status < 300; // default 2xx


export default api;
