import HttpCommon from './HttpCommon';

export default class CustomerService extends HttpCommon {
  // create Customer
  createCustomer(customer) {
    return this.post('/register/customer', customer);
  }

  // fetch customer reservations
  fetchCustomerReservations() {
    return this.fetch('/customer/reservations');
  }

  fetchCustomerPendingReservations(itemsPerPage, page) {
    return this.fetch(`/customer/reservations/pending?size=${itemsPerPage}&page=${page}`);
  }

  fetchCustomerAcceptedTodayReservations(itemsPerPage, page) {
    return this.fetch(`/customer/reservations/accepted/today?size=${itemsPerPage}&page=${page}`);
  }

  fetchCustomerAcceptedFutureReservations(itemsPerPage, page) {
    return this.fetch(`/customer/reservations/accepted/future?size=${itemsPerPage}&page=${page}`);
  }

  fetchCustomerAcceptedPastReservations(itemsPerPage, page) {
    return this.fetch(`/customer/reservations/accepted/past?size=${itemsPerPage}&page=${page}`);
  }

  fetchCustomerInactiveReservations(itemsPerPage, page) {
    return this.fetch(`/customer/reservations/inactive?size=${itemsPerPage}&page=${page}`);
  }

  fetchServiceDetailsForCustomerReservation(reservationNumber) {
    return this.fetch(`/customer/reservations/${reservationNumber}`);
  }
}
