import Vue from 'vue';
import localForage from 'localforage';
import { sync } from 'vuex-router-sync';
import i18n from '@/plugins/i18n';
import '@/plugins/vee-validate';
import router from '@/plugins/vue-router';
import navigationGuard from '@/routes/hooks/navigationGuard';
import afterEach from '@/routes/hooks/afterEach';
import store from '@/store';
import createInterceptors from '@/plugins/axiosInterceptorConfig';

import App from './App';

Vue.config.productionTip = false;


router.beforeEach(navigationGuard);
// add after each
router.afterEach(afterEach);

// update GA page path
/* router.afterEach((to, from) => {
  if (typeof gtag === 'function') {
    // eslint-disable-next-line no-undef
    gtag('config', 'UA-128485681-1', { page_path: to.fullPath });
    // todo: send page title to GA
  }
}); */

// storage configuration: db Authland
localForage.config({
  name: 'Authland',
  storeName: 'bookingCart',
});

// Sync vue-router's current $route as part of vuex store's state (route module).
sync(store, router);


// configure axios interceptors
createInterceptors(store, router);


// TODO: generate WEB URL's; in the absence of a better solution...

const WEB_STAGING_URL = 'https://authland-staging.com';
const WEB_PROD_URL = 'https://authland.com';
const WEB_DEV_URL = 'http://authland.test:8000';

const ORIGIN = window.location.origin;

const IS_PROD_BUILD = process.env.NODE_ENV === 'production';

// eslint-disable-next-line no-nested-ternary
const WEB_URL = IS_PROD_BUILD ? (ORIGIN.includes('staging') ? WEB_STAGING_URL : WEB_PROD_URL) : WEB_DEV_URL;


Vue.directive('web-url', (el, binding) => {
  const { value, modifiers } = binding;
  el.href = new URL(value || '/', WEB_URL); // eslint-disable-line no-param-reassign

  if (modifiers.blank) {
    el.target = '_blank'; // eslint-disable-line no-param-reassign
  }
});


/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  i18n,
  components: { App },
  data: {
    appVersion: process.env.VUE_APP_VERSION,
  },
  template: '<App/>',
});
