import store from '@/store';

// helper functions
const getUserRoles = userInfo => ((userInfo != null) ? userInfo.roles : []);
const recordThatRequireRoles = record => Array.isArray(record.meta.requireRoles)
                                  && record.meta.requireRoles.length;
const extractRecordRoles = record => ((record) ? record.meta.requireRoles : null);


const navigationGuard = async (to, from, next) => {
  const isAuthenticated = store.getters['auth/isAuthenticated'];
  // fetch user info if the user is authenticated (userInfo = null -> page reload)
  if (isAuthenticated && store.state.auth.userInfo === null) {
    await store.dispatch('auth/UPDATE_USER_INFO');
  }
  /**
   * Clears all global feedback message
   * that might be visible
   */
  // store.dispatch('resetMessages')

  /**
   * If route doesn't require authentication (any role)
   */
  const requiredRoles = extractRecordRoles(to.matched.find(recordThatRequireRoles));

  if (!requiredRoles) {
    next();
    return;
  }
  /**
   * Otherwise  if authentication and specific role/s are required.
   */
  const userRoles = getUserRoles(store.state.auth.userInfo);
  const hasRequiredRoles = requiredRoles.every(role => userRoles.indexOf(role) > -1);
  if (!hasRequiredRoles) {
    // maybe forbidden ? - redirect to login
    next({ name: 'login', query: { redirect: true } });
    return;
  }

  // has required roles - let him go
  next();
};

export default navigationGuard;
