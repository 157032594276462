// default vee-validate en messages
import defaultEnValidation from 'vee-validate/dist/locale/en';
import en from './en_US/general.json';
import enValidationMessages from './en_US/validationMessages';
import enValidationAttributes from './en_US/validationAttributes';


export default {
  translations: en,
  validations: {
    en: {
      name: 'en',
      messages: Object.assign(defaultEnValidation.messages, enValidationMessages),
      attributes: enValidationAttributes,
    },
  },
};
