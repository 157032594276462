import roles from '@/routes/userRoles';

// route names
export const names = {
  personal_info_customer: 'personalInfoCustomer',
  purchases: 'customerPurchases',
};

export default [
  {
    path: '/customer',
    component: () => import(/* webpackChunkName: "share" */ '@/share/Layouts/CentralizeBodyContent'),
    meta: {
      requireRoles: [roles.CUSTOMER],
    },
    children: [
      {
        path: 'purchases',
        name: names.purchases,
        component: () => import(/* webpackChunkName: "customer" */ '@/share/Pages/CustomerPurchases'),
      },
      {
        path: 'personal',
        name: names.personal_info_customer,
        component: () => import(/* webpackChunkName: "share" */ '@/share/Pages/PersonalInfoForm'),
      },
    ],
  },
];
