import HttpCommon from './HttpCommon';

const ACCESS_TOKEN_KEY = 'accessToken';

export default class AuthService extends HttpCommon {
  /* static getToken() {
    return window.localStorage.getItem(ACCESS_TOKEN_KEY) || '';
  } */
  getAccessToken = () => window.localStorage.getItem(ACCESS_TOKEN_KEY) || '';

  storeAccessToken = (token) => {
    window.localStorage.setItem(ACCESS_TOKEN_KEY, token);
  }

  removeAccessToken = () => {
    window.localStorage.removeItem(ACCESS_TOKEN_KEY);
  }

  refreshAccessToken() {
    return this.fetch('/token');
  }

  logout() {
    return this.post('/token/destroy');
  }
  /*
  fetchAuthDetails() {
    const accessToken = this.getToken();
    try {
      const details = this.parseToken(accessToken);
      return {
        accessToken,
        details,
      };
    } catch (e) {
      this.removeToken();
    }

    return null;
  },
  parseToken(token) {
    try {
      const payloadBase64Url = token.split('.')[1];

      const payloadBase64 = payloadBase64Url.replace(/-/g, '+').replace(/_/g, '/');

      const decodedPayload = window.atob(payloadBase64);
      return JSON.parse(decodedPayload);
    } catch (e) {
      throw new Error('Error on parsing token');
    }
  },
   */
}
