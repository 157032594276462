import ImgixClient from 'imgix-core-js';


const stagingClient = new ImgixClient({
  domain: 'authland-staging.imgix.net',
  includeLibraryParam: false,
});
const prodClient = new ImgixClient({
  domain: 'authland.imgix.net',
  includeLibraryParam: false,
});

const devClient = new ImgixClient({
  domain: 'replace.me',
  includeLibraryParam: false,
});

const REPLACE_DEV_REGEX = /https:\/\/replace.me/gi;

const DOMAIN_PROD = 'app.authland.com';
const DOMAIN_STAGING = 'app.authland-staging.com';

export function buildURL(photoPath, params = {}) {
  const HOSTNAME = window.location.hostname;

  // production
  if (HOSTNAME === DOMAIN_PROD) {
    return prodClient.buildURL(photoPath, params);
  }
  // staging
  if (HOSTNAME === DOMAIN_STAGING) {
    return stagingClient.buildURL(photoPath, params);
  }

  // dev - localhost
  const out = devClient.buildURL(photoPath, params);

  return out.replace(REPLACE_DEV_REGEX, '/api/photos');
}

export function buildSrcSet(photoPath, params = {}, options) {
  const HOSTNAME = window.location.hostname;

  // production
  if (HOSTNAME === DOMAIN_PROD) {
    return prodClient.buildSrcSet(photoPath, params, options);
  }
  // staging
  if (HOSTNAME === DOMAIN_STAGING) {
    return stagingClient.buildSrcSet(photoPath, params, options);
  }

  // dev - localhost
  const out = devClient.buildSrcSet(photoPath, params, options);

  return out.replace(REPLACE_DEV_REGEX, '/api/photos');
}

export function buildProfilePhotoURL(photoPath) {
  return buildURL(photoPath, {
    w: 120,
    h: 120,
    fit: 'crop',
    crop: 'faces',
    mask: 'ellipse',
    auto: 'format',
  });
}
